import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const projects = [
  {
    image: "portfolio/salesforce/glamourshot.png",
    title: "Salesforce Trailhead Profile",
    description: "My Salesforce Trailhead accomplishments and badges.",
    skillsUsed: [
      "Business Administration Superbadge",
      "Reporting",
      "App Logic",
      "Security",
      "Database",
      "Apex",
    ],
    link: "https://trailblazer.me/id/ashlynnroarick",
    linkText: "Check it Out!",
  },
  {
    image: "portfolio/venegasmaxwellhomes/glamourshot.png",
    title: "Venegas Maxwell Homes",
    description: "A website for the real estate company Venegas Maxwell Homes",
    skillsUsed: ["ReactJS", "NodeJS", "JSON"],
    link: "http://sarahmaxwell.ashlynn.online/",
    linkText: "Check it Out!",
  },
  {
    image: "portfolio/allynsbreadbakery/glamourshot.png",
    title: "Allyn's Wild Flour Shoppe",
    description: "A mock website for a bread bakery",
    skillsUsed: ["ReactJS", "NodeJS", "JSON"],
    link: "http://bakery.ashlynn.online/",
    linkText: "Check it Out!",
  },
  {
    image: "portfolio/dribbble/glamourshot.png",
    title: "Dribbble Profile",
    description: "Just my Dribbble profile.",
    skillsUsed: ["Photoshop", "Gimp"],
    link: "https://dribbble.com/aroarick1",
    linkText: "Check it Out!",
  },
  {
    image: "portfolio/sothisisawkward/glamourshot.jpg",
    title: "So This Is Awkward",
    description:
      "Have you ever had a boss that has STANKY breath? Or maybe your wife talks too loud? So This Is Awkward can handle those awkward conversations for you before they happen.",
    skillsUsed: ["HTML", "CSS", "React.js", "SendGrid", "mySQL"],
    link: "http://sothisisawkward.com/",
    linkText: "Check it Out!",
  },
  {
    image: "portfolio/hangman/glamourshot.jpg",
    title: '"Hangman" game',
    description:
      "A classic game of Hangman with an 80's feel. Try to guess bands, songs, movies, and popular toys that were used during those radical times!",
    skillsUsed: ["HTML", "CSS", "Vanilla JS"],
    link: "portfolio/hangman/index.html",
    linkText: "Play It!",
  },
  {
    image: "portfolio/giftastic/glamourshot.jpg",
    title: '"Giftastic" Search engine',
    description:
      "Enter a term into the search box. As you enter terms into the search box, buttons will appear above with the term entered and gifs related to that term will appear below.",
    skillsUsed: ["HTML", "CSS", "Javascript", "jQuery", "giphy API"],
    link: "portfolio/giftastic/index.html",
    linkText: "Use It!",
  },
  {
    image: "portfolio/trivia/glamourshot.jpg",
    title: "Classic 80s Movies Trivia Game",
    description:
      "When you click Start, you will be given 10 multiple-choice questions that cover classic 80's movies. You have 20 seconds to answer each of the questions before time runs out.",
    skillsUsed: ["HTML", "CSS", "Javascript", "jQuery"],
    link: "portfolio/trivia/index.html",
    linkText: "Play It!",
  },
];

class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiProjects: [],
      dataProjects: [],
    };
  }

  componentDidMount() {
    let cards = projects.map((item, index) => {
      return (
        <Col key={index} md={6} lg={4}>
          <Card>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <Card.Img className="work-image" variant="top" src={item.image} />
            </a>
            <Card.Body>
              <Card.Title>{item.title}</Card.Title>
              <Card.Text>{item.description}</Card.Text>
              {/* <Card.Text> */}
              <ul>
                {item.skillsUsed.map((li, index) => {
                  return <li key={index}>{li}</li>;
                })}
              </ul>
              {/* </Card.Text> */}
              <Button
                className={
                  item.link.length === 0
                    ? "btn btn-outline-pinky hidden"
                    : "btn btn-outline-pinky"
                }
                target="_blank"
                href={item.link}
              >
                {item.linkText}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      );
    });

    this.setState({ uiProjects: cards });
  }

  render() {
    return (
      <>
        <Row className="work">
          <Col md={1} sm={0} className="extra-col detail-col"></Col>
          <Col>
            <Row>{this.state.uiProjects}</Row>
          </Col>
          <Col md={1} sm={0} className="extra-col detail-col"></Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </>
    );
  }
}
export default Work;
