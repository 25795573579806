import React from "react";
import Footer from "../Footer/Footer.js";
import Work from "../Work/Work.js";
import Skills from "../Skills/Skills.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { FaHeart } from "react-icons/fa";

function Home() {
  return (
    <Container fluid="true">
      <Row>
        <Col>
          <h1 className="title text-center">Full-stack Developer</h1>
          <h3 className="subtitle text-center">
            I <FaHeart className="red-text" /> what I do
          </h3>
          <Image
            src="ashlynn-memoji-medium.png"
            className="mx-auto d-block ashlynn-image"
          />
          <Image
            src="images/mtns.png"
            className="img-fluid w-100 img-responsive"
          />
        </Col>
      </Row>

      <Row className="blue-row">
        <Col>
          <h3 className="subtitle text-center">
            Hi, I'm Ashlynn. Nice to meet you.
          </h3>
          <p>
            Since I began learning how to program several years ago, and the
            details of front-end development, I realized where my passions lie.
            I find great pleasure in building high quality user interfaces that
            meet and exceed the requirements of my users.
          </p>
        </Col>
      </Row>

      <Skills></Skills>
      <h1 className="title text-center" style={{ marginTop: "45px" }}>
        Take a look at what i've been doing lately
      </h1>
      <Work></Work>
      <Footer></Footer>
    </Container>
  );
}

export default Home;
