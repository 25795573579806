import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Navigation() {
  return (
    <>
      <Navbar bg="blue" variant="dark" expand="lg">
        <Navbar.Brand href="/">Ashlynn Roarick</Navbar.Brand>
        <Nav className="ml-auto">
          {/* <Nav.Link href="/blog">
            <button type="button" className="btn btn-outline-pinky">
              Blog
            </button>
          </Nav.Link> */}
          <Nav.Link href="mailto:itsme@ashlynn.online">
            <button type="button" className="btn btn-outline-pinky">
              Say hey!
            </button>
          </Nav.Link>
        </Nav>
      </Navbar>
      <Row className="info-bar">
        <Col className="left">
          <a href="tel:4697826376">469.782.6376</a>
        </Col>
        <Col className="right">
          <a href="mailto: itsme@ashlynn.online">itsme@ashlynn.online</a>
        </Col>
      </Row>
    </>
  );
}

export default Navigation;
