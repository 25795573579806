import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPenFancy } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa";
import { FaServer } from "react-icons/fa";

const skills = [
  {
    col: "left-col",
    title: "Designer",
    icon: "FaPenFancy",
    description:
      "Art is what drives us and inspires us to become better people. Whether drawing in my notebooks or designing in Photoshop, creating is an important part of who I am.",
    skillsTitle: "Design Skills",
    skills: ["UX", "UI", "Responsive Development"],
    toolsTitle: "Tools",
    tools: ["Photoshop", "Balsamiq", "Pen & Paper", "My Imagination"],
  },
  {
    col: "center-col",
    title: "Front-end",
    icon: "FaFileCode",
    description:
      "I like to design and code things from scratch. I enjoy bringing my ideas to life in the browser.",
    skillsTitle: "Design Skills",
    skills: ["HTML", "CSS", "SASS", "Javascript", "React.js"],
    toolsTitle: "Tools",
    tools: ["Bootstrap 3/4", "Git/Github/Gitlab", "Git Bash"],
  },
  {
    col: "right-col",
    title: "Back-end",
    icon: "FaServer",
    description:
      "I have a passion for developing applications or improving existing ones. I also enjoy being up to date with all the new technologies.",
    skillsTitle: "Skills",
    skills: ["Express", "Sequelize", "Node.js", "Java"],
    toolsTitle: "Tools",
    tools: ["MySQL", "Sendgrid", "Postman"],
  },
];

class Skills extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uiSkills: [],
    };
  }

  componentDidMount() {
    let cols = skills.map((item, index) => {
      return (
        <Col
          key={index}
          md={4}
          className={`text-center detail-col ${item.col}`}
        >
          {(() => {
            switch (item.icon) {
              case "FaPenFancy":
                return <FaPenFancy className="skill-icon"></FaPenFancy>;
              case "FaFileCode":
                return <FaFileCode className="skill-icon"></FaFileCode>;
              case "FaServer":
                return <FaServer className="skill-icon"></FaServer>;
              default:
                break;
            }
          })()}
          <h4>{item.title}</h4>
          <p>{item.description}</p>
          <h5>{item.skillsTitle}</h5>
          <div className="hr"></div>
          <ul key={index}>
            {item.skills.map((li, index) => {
              return <li key={index}>{li}</li>;
            })}
          </ul>
          <h5>{item.toolsTitle}</h5>
          <div className="hr"></div>
          <ul>
            {item.tools.map((li, index) => {
              return <li key={index}>{li}</li>;
            })}
          </ul>
        </Col>
      );
    });

    this.setState({ uiSkills: cols });
  }
  render() {
    return (
      <Row className="blue-row">
        <Col md={1} sm={0} className="extra-col detail-col">
          <div className="filler"></div>
        </Col>
        <Col>
          <Row>{this.state.uiSkills}</Row>
        </Col>
        <Col md={1} sm={0} className="extra-col detail-col">
          <div className="filler"></div>
        </Col>
      </Row>
    );
  }
}

export default Skills;
