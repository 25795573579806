import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { FaLinkedinIn } from "react-icons/fa";
import { FaGitlab } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaSalesforce } from "react-icons/fa";

function Footer() {
  return (
    <>
      <Row className="footer-row">
        {/* <Col>
          <Image src="images/logo.png" className="mx-auto d-block " />
        </Col> */}
      </Row>
      <Row className="footer-row-icons">
        <Col>
          <Button
            className="btn btn-outline-rel"
            href="https://trailblazer.me/id?lang=en_US"
            target="_blank"
          >
            <FaSalesforce size={"3em"}></FaSalesforce>
          </Button>
          <Button
            className="btn btn-outline-rel"
            href="http://bit.ly/linkedin-ashlynn"
            target="_blank"
          >
            <FaLinkedinIn size={"3em"}></FaLinkedinIn>
          </Button>
          <Button
            className="btn btn-outline-rel"
            href="http://bit.ly/gitlab-ashlynn"
            target="_blank"
          >
            <FaGitlab size={"3em"}></FaGitlab>
          </Button>
          <Button
            className="btn btn-outline-rel"
            href="http://bit.ly/github-ashlynn"
            target="_blank"
          >
            <FaGithub size={"3em"}></FaGithub>
          </Button>

          <Button
            className="btn btn-outline-rel"
            href="mailto:itsme@ashlynn.online"
          >
            <FaEnvelope size={"3em"}></FaEnvelope>
          </Button>
          <Button className="btn btn-outline-rel" href="tel:4697826376 ">
            <FaPhone size={"3em"}></FaPhone>
          </Button>
          <Button
            title="Resume"
            className="btn btn-outline-rel"
            href="Ashlynn.Resume.2021.pdf"
            target="_blank"
          >
            <p>Resume</p>
            {/* <FaFile size={"3em"}></FaFile> */}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Footer;
